<template>
  <div class="verify-div">
    <div class="img-dev">
      <img class="img1" :src="portal30">
      <p class="verify-t">{{ $t('ebClaim.TrackingNotFoundTitle') }}</p>
      <p class="verify-s">{{ $t('ebClaim.TrackingNotFoundDesc') }}</p>
    </div>

    <div class="btn-group">
      <Button
        class="fu-v2-btn block"
        round
        block
        type="danger"
        @click="$router.push({name: 'EbTrackClaim'})"
      >{{ $t('ebClaim.TrackingNotFoundBackBtn') }}</Button>
      <Button
        class="fu-v2-btn block"
        round
        block
        type="danger"
        @click="onBackHomepage"
      >{{ $t('ebClaim.BackToHomepage') }}</Button>
    </div>
  </div>
</template>

<script>
import { Button } from 'vant'
import portal30 from '@/assets/imgs/portal30.png'
import { dispatchAction } from '@/utils/dispatch'
import('@/views/claim/eb/css/reset.css')
export default {
  components: { Button },
  props: {},
  data() {
    return {
      portal30
    }
  },
  methods: {
    onBackHomepage() {
      dispatchAction({
        method: 'jumpToHomePage'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.verify-div {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 91;
  background-color: #fff;
}
.img-dev {
  margin-top: 111px;
  text-align: center;
}
.img1 {
  width: 270px;
}
.verify-t {
  font-size: 16px;
  font-family: 'Montserrat-Bold';
  margin: 15px 0;
}
.verify-s {
  font-size: 12px;
  margin: 0 45px;
  text-align: center;
}
.btn-group {
  margin: 20px 20px;
}
</style>
